body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
  border-radius: 10px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px 8px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 300px;
}

.dark-modal-content {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: rgba(255, 255, 255, .95);
  padding: 8px 8px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 300px;
}
.modal-content-test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px 8px;
  border-radius: 10px;
  max-width: 600px;
  min-width: 300px;
}

.modal-content img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  border-radius: 15px;
}

.fate-total {
  text-align: center;
  margin-bottom: 10%;

}
.fate-1 {
  font-size: 1.25rem;
  text-shadow: 1px 1px rgb(0, 0, 0);
  color:rgb(136, 135, 135);
}

.message-1 {
  font-size: 1.15rem;
}

.disclaimer {
  font-size: .75rem;
  text-shadow: .75px .75px gray;
  text-align: right;
}
