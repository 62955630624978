.button-container{
 font-size: 22px;
 color:  rgb(60,60,60);
 background: none; 
 border: 1px solid rgb(60,60,60);
 padding: 5px 30px;
 letter-spacing: 2px;
} 

.button-container:hover{
    border: 1px solid rgb(220, 220, 220);
    color: rgb(220, 220, 220);
    cursor: pointer;
}