.loader-main {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader-title {
  color: var(--default-gray);
  margin-top: 10%;
  text-align: center;
 font-size: 3rem;
}
