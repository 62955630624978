.landing-container {
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.landing-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-title {
  color: white;
  font-family: inherit;
  font-size: 68px;
  user-select: none;
}

.landing-back-button{
  position: absolute;
  top: 1rem;
  left: 1rem;
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.2rem;
  font-weight: 600;

  padding: 10px;
}
.landing-profiles {
  display: flex;
  /*
    flex-wrap: row wrap;
  justify-content: space-around;
  */
}
.landing-profile {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.landing-profile:first-child {
  margin-left: 0px;
}
.landing-profile:last-child {
  margin-right: 0px;
}

.landing-profile:hover {
  cursor: pointer;
}

.landing-profile:hover .landing-display {
  color: rgb(220, 220, 220);
}

.landing-profile:hover .landing-image {
  border: 5px solid white;
}

.landing-profile .landing-image {
  margin-bottom: 15px;
  object-fit: cover;
  width: 200px;
  height: 200px;
  border: 5px solid transparent;
}

.landing-profile .landing-display {
  color: var(--default-gray);
  text-align: center;
  font-size: 22px;
}

@media only screen and (max-width: 650px) {
 
 
  .landing-title {
    text-align: center;
    font-size: 2em;
    padding: 10px;
    margin-top: -20px;
  }
  .landing-content {
   width: 100vw;
    height: 90vh;
    display: flex;
   
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing-profiles {
    width: 75vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:space-evenly;
    padding: 30px;
  }

  .landing-profile {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  
    margin: 10px 0px;
  }
  .landing-profile .landing-image {
    margin-bottom: 5px;
    object-fit: cover;
    width: 100px;
    height: 100px;
    border: 5px solid transparent;
  }

  .landing-profile .landing-display {
    color: var(--default-gray);
    text-align: center;
    font-size: 18px;
  }
}

