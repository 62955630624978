:root {
  --main-radius: 5px;
  --main-padding: 15px;
}

.details-container {
  background: rgb(30, 30, 30);
  color: white;
  display: grid;
  min-height: 100vh;

  grid-gap: 0.3rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 0.002fr 1fr 1fr 1fr 0.25fr;

  grid-template-areas:
    "title title title"
    "titleInfo titleInfo titleInfo"
    "positionInfoBlock main main"
    "positionInfoBlock main main"
    "positionInfoBlock main main"
    "footer footer footer";
}

#details-navbar {
  grid-area: navbar;
  background: #1de9b6;
}

#details-back-button {
  position: absolute;
  top: 0.5rem;
  left: 0.8rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.details-title-SG {
  grid-area: title;
  background: rgb(201, 163, 5);
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-SF {
  grid-area: title;
  background: rgb(0, 198, 222);
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-PG {
  grid-area: title;
  background: rgb(23, 168, 62);
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-PF {
  grid-area: title;
  background: rgb(52, 54, 177);
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-C {
  grid-area: title;
  background: rgb(126, 20, 2);
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}
.details-title-Center {
  grid-area: title;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #e4c53b 0%,
    rgb(97, 2, 2) 100%
  );
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}
.details-title-Point_Guard {
  grid-area: title;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 40%,
    rgb(23, 168, 62) 100%
  );
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-Shooting_Guard {
  grid-area: title;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #faffd0 0%,
    rgb(180, 145, 3) 100%
  );
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-Power_Forward {
  grid-area: title;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 0%,
    rgb(52, 54, 177) 100%
  );
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title-Small_Forward {
  grid-area: title;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 0%,
    rgb(0, 198, 222) 100%
  );
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

.details-title {
  grid-area: title;
  background: #4d4a53;
  border-radius: var(--main-radius);
  text-align: center;
  font-weight: 400;
}

#details-character-name {
  font-size: 3.5rem;
  font-weight: 600;
}

#details-position-name {
  font-weight: 600;
  font-size: 21px;
  text-shadow: 2px 2px rgb(100, 100, 100);
}

/*elite skills link*/
.details-fake-link {
  text-shadow: 2px 2px rgb(128, 128, 128);
  font-size: 1.5rem;
}
.details-fake-link:hover {
  cursor: pointer;
  text-shadow: 3px 3px rgb(128, 128, 128);
  font-size: 1.65rem;
  transition: all 0.3s ease-in-out;
}
.details-fake-link-footer {
  background: rgb(100, 100, 100);
  padding-left: 5px;
  padding-right: 5px;
}
.details-fake-link-footer:hover {
  cursor: pointer;
  text-shadow: 2px 2px rgb(48, 48, 49);
}

.details-title-info {
  grid-area: titleInfo;
  background: rgb(30, 30, 30);
  display: flex;
  justify-content: space-evenly;
  padding-top: var(--main-padding);
 
  border-top: rgb(53, 53, 53);
  border-top-style: solid;
  border-top-width: 5px;
  

}

.SG {
  grid-area: positionInfo0;
  background: rgb(201, 163, 5);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.SF {
  grid-area: positionInfo1;
  background: rgb(0, 198, 222);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}
.PG {
  grid-area: positionInfo2;
  background: rgb(23, 168, 62);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.PF {
  grid-area: positionInfo0;
  background: rgb(52, 54, 177);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.C {
  grid-area: positionInfo1;
  background: rgb(126, 20, 2);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.Center {
  grid-area: positionInfo0;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #e4c53b 0%,
    rgb(97, 2, 2) 100%
  );
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.Point_Guard {
  grid-area: positionInfo0;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 0%,
    rgb(23, 168, 62) 100%
  );
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.Shooting_Guard {
  grid-area: positionInfo0;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #faffd0 0%,
    rgb(180, 145, 3) 100%
  );
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.Power_Forward {
  grid-area: positionInfo0;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 0%,
    rgb(52, 54, 177) 100%
  );
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.Small_Forward {
  grid-area: positionInfo0;
  background-image: radial-gradient(
    farthest-corner at 90px 940px,
    #f4a460 0%,
    rgb(0, 198, 222) 100%
  );
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.details-position-info-block {
  grid-area: positionInfoBlock;
  background: rgb(0, 0, 0);
  color: white;
  letter-spacing: 1px;
  word-spacing: 1px;
  border-radius: var(--main-radius);
}

.details-position-info0 {
  grid-area: positionInfo0;
  background: rgb(30, 30, 30);
  color: white;

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}

.details-position-info1 {
  grid-area: positionInfo1;
  background: rgb(30, 30, 30);

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}
.details-position-info2 {
  grid-area: positionInfo2;
  background: rgb(30, 30, 30);

  border-radius: var(--main-radius);
  padding: var(--main-padding);
}
.details-padded-div {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.details-header {
  color: white;
}

.details-stats {
  font-weight: 600;
}
.details-stats-positive {
  color: #00ffff;
  margin-left: 5px;
}

.details-stats-negative {
  color: #f54242;
  margin-left: 5px;
}
.details-stats-neutral {
  color: rgb(209, 198, 162);
  margin-left: 3px;
}

#details-rating {
  background: rgb(100, 100, 100);
  padding: 10px;
  grid-area: rating;
  margin-bottom: 5px;
  border-radius: var(--main-radius);
}
#details-cost {
  grid-area: cost;
  background: rgb(100, 100, 100);
  padding: 10px;
  margin-bottom:5px;
  border-radius: var(--main-radius);
}

#details-TBD {
  background: rgb(100, 100, 100);
  grid-area: TBD;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: var(--main-radius);
}

.details-footer {
  grid-area: footer;
  background: rgb(30, 30, 30);
  display: flex;
  justify-content: space-evenly;
  padding-top: var(--main-padding);
  padding-bottom: var(--main-padding);

  border-top: rgb(53, 53, 53);
  border-top-style: solid;
  border-top-width: 5px;
  

}

#details-footer-subcontent {
  grid-area: avatar;
  background: rgb(100, 100, 100);
  padding: 8px;
  border-radius: var(--main-radius);
}

.details_icons_text {
  margin-left: 5px;
  margin-right: 5px;

  text-align: center;
  padding: 5px;
  font-size: 1.1rem;

  background: #2f4f4f;
  border-radius: var(--main-radius);
}

.details-main {
  grid-area: main;
  background-color: #000000;

  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
}
.details-main img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
  margin-left: 0.6rem;
  border-radius: 15px;
  
}

.home-container:first-child {
  margin-top: 50px;
}

.home-container:last-child {
  margin-bottom: 80px;
}
@media only screen and (max-width: 650px) {
  .details-container {
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 0.4fr 0.1fr 3fr 1fr 0.4fr;
    grid-template-areas:
      "title"
      "titleInfo"
      "main"
      "positionInfoBlock"
      "footer";
  }

  .details-main {
    height: 100vw;
    padding:0.7rem;
  }
  .details-main img {

    max-width: 95%;
    object-fit: cover;
    overflow: hidden;
  }
}
