.list-item {
  width: var(--tileWidth);
  height: var(--tileHeight);

  background-color: #2f4353;
  background-image: linear-gradient(90deg, #2f4353 0%, #d2ccc4 94%);
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
}

.list-item > img {
  object-fit: cover;
  width: 97%;
  height: 140%;
  border: 5px solid transparent;
}

.list-item:hover {
  width: 245px;
  height: 260px;
  border: 5px solid white;
  margin-top: -15px; 
}

.list-item:hover > img {
  height: 210px;
  transition: all 0.3s ease; 
}

.list-item > .list-item-characterTitle {
  position: absolute;
  top: 6px;
  left: 10px;
  color: rgb(255, 255, 255);
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  font-size: 22px;
  text-shadow: 1px 1px 0 rgb(180, 180, 180);
}

.list-item-characterInfo {
  display: flex;

  padding: 5px;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}

.list-item-positionInfo {
  display: flex;
  margin-bottom: 5px;
}
.list-item-position1,
.list-item-position2,
.list-item-position3 {
  border-radius: 100%;
  width: 17px;
  height: 17px;
  text-align: center;
  font-size: 14px;
  padding: 4px;
  border: 2px solid white;
  background-color: var(--default-gray);
}



@media only screen and (max-width: 1020px) {
  .list-item {
    width: 125px;
    height: 110px;
  
    background-color: #2f4353;
    background-image: linear-gradient(90deg, #2f4353 0%, #d2ccc4 94%);
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
  }
  
  .list-item > img {
    object-fit: cover;
    width: 97%;
    height: 140%;
    border: 5px solid transparent;
  }
  
  
.list-item:hover {
  width: 125px;
  height: 110px;

  background-color: #2f4353;
  background-image: linear-gradient(90deg, #2f4353 0%, #d2ccc4 94%);
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.list-item:hover > img {
  object-fit: cover;
  width: 97%;
  height: 140%;
  border: 5px solid transparent;
}


  .list-item > .list-item-characterTitle {
    position: absolute;
    top: 6px;
    left: 10px;
    color: rgb(255, 255, 255);
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bold;
    font-size: 22px;
    text-shadow: 1px 1px 0 rgb(180, 180, 180);
  }
  
  .list-item-characterInfo {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    color: white;
    font-size: 20px;
  /*font-family: "Roboto", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
  }
  
  .list-item-positionInfo {
    display: flex;
    margin-bottom: 5px;
  }
  .list-item-position1,
  .list-item-position2,
  .list-item-position3 {
    border-radius: 100%;
    width: 17px;
    height: 17px;
    text-align: center;
    font-size: 14px;
    padding: 4px;
    border: 2px solid white;
    background-color: var(--default-gray);
  }
}
