@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto:wght@300;400;500;700;900&display=swap');

:root{
  --default-gray: rgb(100, 100, 100);
  --tileWidth: 225px;
  --tileHeight: 140px;
  --main-radius: 5px;
  --main-padding: 15px;
  --skill-tileWidth:125px;
  --skill-tileHeight:115px;
}